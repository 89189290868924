var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentUser.superAdmin)?_c('div',[_c('div',{staticClass:"d-flex flex flex-row justify-content-between pb-2"},[_c('div',{staticClass:"w-300px pl-4 pb-4"},[_c('v-combobox',{attrs:{"items":_vm.countries,"label":_vm.$t('commonTable.country'),"item-text":"name","item-value":"id","outline":""},on:{"change":_vm.onChangeCountry},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),_c('router-link',{attrs:{"to":{
        name: 'main-feed-categories-add'
      }}},[_c('v-btn',{staticClass:"ma-2 white--text p-4",attrs:{"color":"green"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")]),_vm._v(" add new MF category ")],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 m-4 mt-0",attrs:{"headers":_vm.headers,"items":_vm.mfCategories,"loading":_vm.loadingMfCategories,"disable-pagination":true,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
      var item = ref.item;
return [(item != null && item.image != null)?_c('v-img',{staticClass:"radius-50",attrs:{"src":item.image,"contain":"","max-height":"32","max-width":"32"}}):_vm._e()]}},{key:"item.published",fn:function(ref){
      var item = ref.item;
return [_c('v-switch',{on:{"click":function($event){return _vm.changePublish(item.id, item.published)}},model:{value:(item.published),callback:function ($$v) {_vm.$set(item, "published", $$v)},expression:"item.published"}})]}},{key:"item.city",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.cityName ? item.cityName : "All cities")+" ")]}},{key:"item.categoryIds",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.id ? item.id : 0)+" ")]}},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.ord",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.ord)+" ")]}},{key:"item.type",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.type)+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('router-link',{attrs:{"to":{
              name: 'main-feed-categories-add',
              params: { categoryEdit: item }
            }}},[_c('v-list-item',[_vm._v(" "+_vm._s(_vm.$t("common.edit"))+" ")])],1),_c('router-link',{attrs:{"to":{
              name: 'main-feed-categories-add',
              params: { categoryCopy: item }
            }}},[_c('v-list-item',[_vm._v(" "+_vm._s(_vm.$t("common.copy"))+" ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.delRecord(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("common.delete")))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.onOpenDialog(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("common.deepLink")))])],1)],1)],1)]}}],null,false,2578517726)}),_c('ConfirmDialog',{ref:"confirm"}),_c('v-dialog',{attrs:{"width":"500px"},model:{value:(_vm.deepLinkDialog),callback:function ($$v) {_vm.deepLinkDialog=$$v},expression:"deepLinkDialog"}},[_c('div',{staticClass:"deep-link-dialog"},[_c('div',{staticClass:"deep-link-dialog__header"},[_c('h3',{staticClass:"deep-link-dialog__title"},[_vm._v("Copy Deep Link")]),_c('v-icon',{on:{"click":function($event){_vm.deepLinkDialog = false}}},[_vm._v("mdi-close")])],1),(!_vm.deeplinkLoading)?_c('div',{staticClass:"deep-link-dialog__content"},[_c('div',{staticClass:"deep-link-wrap"},[_c('p',{staticClass:"deep-link-text"},[_vm._v(" "+_vm._s(this.deepLinkText)+" ")]),_c('v-icon',{staticClass:"copy",on:{"click":function($event){return _vm.copyDeepLinkText()}}},[_vm._v("content_copy")])],1)]):_vm._e(),(_vm.deeplinkLoading)?_c('div',{staticClass:"loading-spinner"},[_c('div',{staticClass:"lds-ring"},[_c('div'),_c('div'),_c('div'),_c('div')])]):_vm._e()])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }